<template>
  <div class="container flex-column-center">
    <div class="import-wrapper">
      <el-form ref="form" :model="form" :rules="rules" label-width="0" class="import-form" style="width:300px">
        <el-form-item prop="file">
          <div class="flex-center import-form-upload">
            <el-upload
              accept=".xlsx"
              drag
              :file-list="fileList"
              :before-upload="beforeUpload"
            >
              <div class="import-form-upload-box flex-column-center">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__text">{{fileName}}</div>
              </div>
            </el-upload>
          </div>
        </el-form-item>

        <div class="flex-center import-bbar">
          <el-button type="primary" :loading="saving || result.state === 'ing'" @click="submit">确定</el-button>
        </div>
      </el-form>

      <div class="import-tip" v-if="result">
        <div>执行状态{{result.state}}</div>
        <div v-if="result.result">
          <div class="import-tip-row">上传结果：</div>
          <div class="import-tip-row">主播：成功{{result.result.host.suc}}条，失败:{{result.result.host.fails.join('\n')}}</div>
          <div class="import-tip-row">专辑：成功{{result.result.album.suc}}条，失败:{{result.result.album.fails.join('\n')}}</div>
          <div class="import-tip-row">单曲：成功{{result.result.track.suc}}条，失败:{{result.result.track.fails.join('\n')}}</div>
          <div class="import-tip-row">单曲歌词：成功{{result.result.track_lrc.suc}}条，失败:{{result.result.track_lrc.fails.join('\n')}}</div>
          <div class="import-tip-row">专辑单曲排序：成功{{result.result.track_sort.suc}}条，失败:{{result.result.track_sort.fails.join('\n')}}</div>
          <div class="import-tip-row">难度等级：成功{{result.result.question_weight.suc}}条，失败:{{result.result.question_weight.fails.join('\n')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        file: null
      },

      rules: {
        file: { required: true, message: '请选择数据文件' }
      },
      saving: false,
      fileName: '',

      fileList: [],
      result: {
        state: null
      }
    }
  },

  unmount() {
    this.stopTimer()
  },

  created() {
    this.reloadStatus()
  },

  methods: {
    beforeUpload(file) {
      this.form.file = file
      this.fileName = file.name
      return false
    },

    submit() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSubmit()
      })
    },

    async doSubmit() {
      if (this.saving || this.result.state === 'ing') return
      try {
        this.result = { state: null }
        this.saving = true
        const form = new FormData()
        form.append('file', this.form.file)
        const { data } = await this.request({
          method: 'POST',
          url: '/api/admin/resource/import',
          data: form
        })

        if (!data) this.$message.error('导入失败，请等待上次导入完成')
        else {
          this.result = { state: 'ing' }
          this.checkStatusLater()
        }
      } finally {
        this.saving = false
      }
    },

    stopTimer() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
    },

    checkStatusLater() {
      this.stopTimer()
      this.timer = setTimeout(() => this.reloadStatus(), 3000)
    },

    async reloadStatus() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/import/state'
      })
      if (data.state === 'ing') {
        this.result = {
          state: data.state,
        }
        this.checkStatusLater()
        return
      }

      if (data.state === 'done') {
        this.result = {
          state: data.state,
          result: data.result
        }
      } else if (data.state === 'error') {
        this.result = {
          state: data.state,
        }
      }
    }
  }
}
</script>

<style>
.import-form{
  padding:12px 0;
  border-radius:6px;
  border:1px solid #ebebeb;
}

.import-bbar{
  padding-top:12px;
  border-top:1px solid #ebebeb;
}

.import-form-upload{
  width:100%;
  padding:12px 12px 0 12px;
}

.import-form-upload .el-icon-upload{
  font-size:32px;
}

.import-form-upload-box{
  width:200px;
  height:100px;
}

.import-wrapper{
  position:relative;
}

.import-tip{
  position:absolute;
  top:50%;
  right:0;
  transform:translate(100%, -50%);
  padding:12px;
}

.import-tip-row{
  line-height:24px;
  white-space:pre-wrap;
  width:200px;
}
</style>
